<template>
  <div class="contenedor-login"> 
    <!-- <div class="logos-login">
      <div class="logo-gobierno">
        <img :src="img.gobierno" alt="Gobierno del Estado de Morelos">
      </div>
      <div class="logo-sepase">
        <img :src="img.sepase" alt="SEPASE">
      </div>
      <div class="logo-gobmorelos">
        <img :src="img.gobmorelos" alt="Gobierno del Estado de Morelos">
      </div>
    </div> -->
    <div class="acceso-login">
      <form @submit.prevent="login">
        <label for="login-email">Correo</label>
        <input type="email" id="login-email" v-model="correo">
        <label for="login-password">Contraseña</label>
        <input type="password" id="login-password" v-model="pass">
        <button type="submit">Ingresar</button>
      </form>
    </div>
    <div class="alerta" v-if="mostrarAlerta">
      <p>{{mensajeAlerta}}</p>
    </div>
    <!-- <div class="logos-imm-sg">
       <div class="logo-secretaria-gob">
        <img :src="img.secretariaGob" alt="Secretaria de Gobienro">
      </div>
      <div class="logo-imm">
        <img :src="img.imm" alt="Instituto de la Mujer para el Estado de Morelos"> 
      </div>
    </div> -->
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  data() {
    return {
      mostrarAlerta: false,
      mensajeAlerta: null,
      correo: null,
      pass: null,
      img:{
        sepase: '../img/logo_sepase.png',
        gobierno:'../img/unnamed.jpg',
        imm:'../img/logo_imm.png',
        gobmorelos: '../img/logo_morelos.jpg',
        secretariaGob: '../img/secretaria_gobierno.png'
      },
      data:[]
    }
  },
  methods:{
    ...mapActions(['guardarUsuaria']),
    login(){
      if (this.correo === null || this.pass === null){
        
        this.alerta('¡Ingrese correo y contraseña!',true)
        return
      }

      if (this.pass === 'PAIMEFTransparente2021'){

        const url = '../json/BD.json';
        fetch(url)
        .then(res => res.json())
        .then(data => this.obtenerData(data))
      }else{
        this.alerta('Contraseña Incorrecta',true)
      }
    },
    alerta(mensaje,mostrar){
      this.mensajeAlerta = mensaje
        this.mostrarAlerta = mostrar
        setTimeout(() => {
          this.mostrarAlerta = !mostrar
        }, 3000);
    },
    obtenerData(usuaria){
      
      const filtrar = usuaria.filter(usuaria => usuaria.correo === this.correo)

      if(filtrar != 0){
          const { nombre, dependencia } = filtrar[0]

          const saveUsuaria = {
            nombre,
            dependencia
          }

          this.guardarUsuaria(saveUsuaria)
      
          this.$router.push('/dashboard')
      }else{
        this.alerta('Correo incorrecto',true)
      }

    

    }
  }
}
</script>
